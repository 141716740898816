$(document).ready(function() {
  $('link[rel="stylesheet"]').each(function() {
    const href = $(this).attr('href') + '?t=' + $.now();
    $(this).attr('href', href);
  });

  $('script[src]').each(function() {
    const src = $(this).attr('src') + '?t=' + $.now();
    $(this).attr('src', src);
  });

  $('img[src]').each(function () {
    const src = $(this).attr('src') + '?t=' + $.now();
    $(this).attr('src', src);
  });
});
